<template>
  <b-row class="users">
    <b-col>
      <div class="m-3 text-center">
        <div>{{ $t("admin.posts-outsource") }}</div>
        <b-link to="/" target="_blank">{{ $t("admin.open-home") }}</b-link>
      </div>
    </b-col>
  </b-row>
</template>


<template>
  <b-row class="posts">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.posts") }}
          </h5>
        </div>
      </b-row>
      <b-row>
        <b-table
          id="table"
          hover
          ref="table"
          responsive
          :items="loadPosts"
          :fields="fields"
          per-page="0"
          class="small nowrap"
          :current-page="page"
        >
        <template #cell(name)="data">
            <b-link :to="data.item.url">{{ data.item.user.name }}</b-link>
          </template>
          <template #cell(status)="data">
            <b-row>
                <b-col v-if="data.item.is_approved == 0">Pending</b-col>
                <b-col v-if="data.item.is_approved == 1">Approved</b-col>
                <b-col v-if="data.item.is_approved == 2">Declined</b-col>
              </b-row>
          </template>
          <template #cell(actions)="data">
            <b-button
                @click.prevent="readMore(data.item.url)"
                variant="info"
                size="sm"
                class="mr-1"
                >{{ $t("admin.read-more") }}</b-button
              >
            <b-button
                @click.prevent="approve(data.item)"
                variant="primary"
                size="sm"
                class="mr-1"
                v-if="data.item.is_approved == 0 || data.item.is_approved == 2"
                >{{ $t("admin.approve") }}</b-button
              >
              <b-button
                @click.prevent="decline(data.item)"
                variant="danger"
                size="sm"
                v-if="data.item.is_approved == 1 || data.item.is_approved == 0"
                >{{ $t("admin.decline") }}</b-button
              >
          </template>
          
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
          align="center"
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Post from "../../models/Post";
export default {
  data: function () {
    return {
      posts: [],
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.id") },
        { key: "name", label: this.$t("admin.added-by") },
		{ key: "created_at", label: this.$t("admin.date") },
        { key: "status", label: this.$t("admin.status") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ],
    };
  },
  watch: {
    q() {
      this.reset();
    },
    type() {
      this.reset();
    },
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadPosts(ctx, callback) {
      let url = "/posts?page="+ctx.currentPage;
      this.$aGet(
        url,
        (data) => {
          let posts = [];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.total = data.total;
          this.perPage = data.per_page;
          callback(posts);
        },
        (errors) => {
          console.log("dsd",errors);
        /*  let users = [];
          callback(users); */
        }
      ); 
    },
    approve(post) {
      this.$aPost(
        "/posts/approve/" + post.id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
          this.$refs.table.refresh();
        }
      );
    },
    readMore(url) {
      this.$router.push({ path: url})
    },
    decline(post) {
      this.$aPost(
        "/posts/decline/" + post.id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
          this.$refs.table.refresh();
        }
      );
    }
  },
};
</script>
