import dayjs from "dayjs";
export default class Restrict {
 
  
    constructor(data) {
      if (!data) {
        return;
      }
      this.id = data.id;
      this.name = data.name;
      this.updated_at = dayjs(data.updated_at).format('ll');
    }
  
    
  }