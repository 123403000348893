import dayjs from "dayjs";
export default class Complaint {
 
  
    constructor(data) {
      if (!data) {
        return;
      }
      this.id = data.id;
      this.complainant_name = data.complainant_name;
      this.complainant_email = data.complainant_email;
      this.url = data.url;
      this.complainant_desc = data.complainant_desc;
      this.created_at = dayjs(data.created_at).format('ll');
    }
  
    
  }