<template>
  <b-row class="users">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.complaints") }}
          </h5>
        </div>
      </b-row>
      <b-row>
        <b-table
          id="table"
          hover
          ref="table"
          responsive
          :items="loadComplaints"
          :fields="fields"
          per-page="0"
          class="small nowrap"
          :current-page="page"
        >
          <template #cell(complainant_name)="data">
            <b-row>
              <b-col>{{data.item.complainant_name}}</b-col>
            </b-row>
          </template>
          <template #cell(complainant_email)="data">
            <b-row>
              <b-col>{{data.item.complainant_email}}</b-col>
            </b-row>
          </template>
          <template #cell(url)="data">
            <b-row>
              <b-col>{{data.item.url}}</b-col>
            </b-row>
          </template>
          <template #cell(complainant_desc)="data">
            <b-row>
              <b-col>{{data.item.complainant_desc}}</b-col>
            </b-row>
          </template>
          <template #cell(created_at)="data">
            <b-row>
              <b-col>{{data.item.created_at}}</b-col>
            </b-row>
          </template>
       
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
          align="center"
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Complaint from "../../models/Complaint";
import Verification from "../../models/Verification";
export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.id") },
        { key: "complainant_name", label: this.$t("admin.name") },
        { key: "complainant_email", label: this.$t("admin.email") },
        { key: "url", label: this.$t("admin.url") },
        { key: "complainant_desc", label: this.$t("admin.message") },
        { key: "created_at", label: this.$t("admin.created") }
      ],
    };
  },
  watch: {
    q() {
      this.reset();
    },
    type() {
      this.reset();
    },
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadComplaints(ctx, callback) {
      let url = "/complaints/list";
      // if (this.type) {
      //   url += "/" + this.type;
      // }
      url += "?page=" + ctx.currentPage + "&q=" + this.q;
      this.$aGet(
        url,
        (data) => {
          let complaints = [];
          console.log("verify data", data)
          if(data.data!=undefined){
            for (let obj of data.data) {
              const user = new Complaint(obj);
              if (obj.verification) {
                user.verification = new Verification(obj.verification);
              }
              complaints.push(user);
            }
          }
          this.total = data.total;
          this.perPage = data.per_page;
          callback(complaints);
        },
        (errors) => {
          console.log("dsd",errors);
        /*  let restrict = [];
          callback(restrict); */
        }
      );
    }
  },
};
</script>
