<template>
  <b-row class="users">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.restricted-words") }}
          </h5>
          <b-nav pills>
            <b-nav-form class="mr-2">
              <b-form-input
                :placeholder="$t('admin.search')"
                v-model="q"
                class="mr-1"
              ></b-form-input>
            </b-nav-form>
        
        <router-link to="/admin/restrict/add/" variant="primary" class="nav-link active router-link-active" tag="button">{{ $t("admin.create-restricted-word") }}</router-link>          
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-table
          id="table"
          hover
          ref="table"
          responsive
          :items="loadRestricts"
          :fields="fields"
          per-page="0"
          class="small nowrap"
          :current-page="page"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.item.avatar" :to="data.item.url" size="sm" />
          </template>
          <template #cell(name)="data">
            <b-link :to="data.item.url">{{ data.item.name }}</b-link>
          </template>
          <template #cell(actions)="data">
           
              <b-button
                variant="primary"
                class="mr-1"
                size="sm"
                :to="'/admin/restrict/edit/' + data.item.id"
                >{{ $t("general.edit") }}</b-button
              >
              <b-button
                @click.prevent="del(data.item)"
                variant="danger"
                size="sm"
                >{{ $t("general.delete") }}</b-button
              >
           
            
          </template>
       
        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
          align="center"
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Media from "../../models/Media";
import Restrict from "../../models/Restrict";
import Verification from "../../models/Verification";
export default {
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        { key: "id", label: this.$t("admin.id") },
        { key: "name", label: this.$t("admin.word") },
        { key: "updated_at", label: this.$t("admin.added-date") },
        { key: "actions", label: this.$t("admin.actions"), tdClass: "full" },
      ],
    };
  },
  watch: {
    q() {
      this.reset();
    },
    type() {
      this.reset();
    },
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadRestricts(ctx, callback) {
      let url = "/restrict/list";
      // if (this.type) {
      //   url += "/" + this.type;
      // }
      url += "?page=" + ctx.currentPage + "&q=" + this.q;
      this.$aGet(
        url,
        (data) => {
          let restrict = [];
          console.log("verify data", data)
          if(data.data!=undefined){
            for (let obj of data.data) {
              const user = new Restrict(obj);
              if (obj.verification) {
                user.verification = new Verification(obj.verification);
              }
              restrict.push(user);
            }
          }
          this.total = data.total;
          this.perPage = data.per_page;
          callback(restrict);
        },
        (errors) => {
          console.log("dsd",errors);
        /*  let restrict = [];
          callback(restrict); */
        }
      );
    },
    del(user) {
      this.$aPost(
        "/restrict/delete/" + user.id,
        {
          _method: "GET",
        },
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
          this.$refs.table.refresh();
        }
      );
    },
    approve(user) {
      this.$aPost(
        "/restrict/verification/approve/" + user.id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
          this.$refs.table.refresh();
        }
      );
    },
    decline(user) {
      this.$aPost(
        "/restrict/verification/decline/" + user.id,
        {},
        () => {
          this.$refs.table.refresh();
        },
        (errors) => {
          console.log(errors);
          this.$refs.table.refresh();
        }
      );
    },
    openPhoto(user) {
      const media = new Media({
        id: 0,
        type: Media.TYPE_IMAGE,
        created_at: new Date(),
        url: user.verification.photo + "?rnd=" + Math.random(),
        screenshot: null,
      });
      this.$showPhotoSwipe([media], 0);
    },
  },
};
</script>
