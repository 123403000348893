<template>
  <b-row>
    <b-col>
      <b-row class="sticky-top bg-white page-header">
        <b-col class="px-3 border-bottom">
          <h5 class="text-uppercase my-3 theme_text_color">{{ $t("general.complaints-policy") }}</h5>
          <h6 class="my-3 theme_text_color">We are doing our best so you will enjoy the content provided.If, in any case, you have a complaint, please let us know.</h6>
          <h6 class="my-3 theme_text_color">All complaints will be reviewed and resolved within seven business days.</h6>
        </b-col>
      </b-row>
      <b-form @submit="saveComplaint">
         
          <b-row>
            <div class="w-100 mx-3 mt-3">
              <ui-form-input
                placeholder="Name"
                type="text"
                name="complainant_name"
                v-model="complainant_name"
                :errors="errors"
                :label="$t('general.name')"
              />
              <ui-form-input
                placeholder="Email"
                type="text"
                name="complainant_email"
                v-model="complainant_email"
                :errors="errors"
                :label="$t('general.complaint-email')"
              />
              <ui-form-input
                placeholder="Url"
                type="text"
                name="url"
                v-model="url"
                :errors="errors"
                :label="$t('general.url')"
              />
              <ui-form-input
                placeholder="Complaint"
                type="text"
                name="complainant_desc"
                v-model="complainant_desc"
                :errors="errors"
                :label="$t('general.complaint')"
                class="description"
              />
              <b-button @click.prevent="saveComplaint" class="send_btn">{{
                $t("general.send")
              }}</b-button>
            </div>
          </b-row>
        </b-form>
    </b-col>
  </b-row>
</template>
<style>
  .send_btn{
    text-align: center;
    background: orange;
    color: #fff;
    border: none;
  }
</style>
<script>
  import UiFormInput from "../ui/UiFormInput.vue";
  export default {
    data: function () {
      return {
        complaint: null,
        errors: {},
        complainant_name: null,
        complainant_email: null,
        url: null,
        complainant_desc: null,
      };
    },
    components: {
      UiFormInput
    },
    methods: {
    reset() {
      this.complainant_name = "";
      this.complainant_email = "";
      this.url = "";
      this.complainant_desc = "";
    },
      saveComplaint() {
      console.log("id", this.$store.state.currentUser.id)
        this.errors = {};
        this.$aPost(
          "/complaint/save",
          {
            _method: "POST",
            user_id: this.$store.state.currentUser.id,
            complainant_name: this.complainant_name,
            complainant_email: this.complainant_email,
            url: this.url,
            complainant_desc: this.complainant_desc,
          
          },
  
          () => {
            this.$bvToast.toast(this.$t("general.sent-complaint"), {
              autoHideDelay: 2000,
              title: this.$t("general.complaint"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            });
           

            this.reset();
           
          },
          (errors) => {
            this.errors = errors;
          }
        );
      }
    }
  }
</script>