<template>
    <b-row class="users-edit" v-if="restrict != null">
      <b-col>
        <b-row class="border-bottom sticky-top bg-white page-header">
          <div class="d-flex align-items-center w-100 mx-3">
            <b-link @click="$router.go(-1)">
              <i class="bi-arrow-left" />
            </b-link>
            <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
              {{ $t("admin.edit-restrict") }}
            </h5>
            <b-button @click.prevent="saveSettings">{{
              $t("general.save")
            }}</b-button>
          </div>
        </b-row>
        <b-form @submit="saveSettings">
         
          <b-row>
            <div class="w-100 mx-3 mt-3">
              
              <ui-form-input
                type="text"
                name="name"
                v-model="restrict.name"
                :errors="errors"
                :label="$t('general.name')"
              />
             
            </div>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </template>
  <script>
  import Restrict from "../../models/Restrict";
  import UiFormInput from "../../ui/UiFormInput.vue";
  export default {
    data: function () {
      return {
        restrict: null,
        errors: {},
      };
    },
    components: {
      UiFormInput
    },
    computed: {
      restrictId() {
        return this.$route.params.id;
      },
      commission() {
        return process.env.VUE_APP_COMMISSION + "%";
      },
    },
    mounted() {
      this.loadRestrict();
    },
    methods: {
      loadRestrict() {
        this.$aGet(
          "/restrict/edit/" + this.restrictId,
          (data) => {
            this.restrict = new Restrict(data);
           
            this.name = data.name;
          },
          (errors) => {
            console.log(errors);
          }
        );
      },
      saveSettings() {
        this.errors = {};
        this.$aPost(
          "/restrict/update/" + this.restrictId,
          {
            _method: "POST",
            
            name: this.restrict.name,
          
          },
  
          (data) => {
            this.restrict = new Restrict(data);
            this.name = data.name;
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.rest-update"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            });
            setTimeout( () => this.$router.push({ path: '/admin/restrict'}), 1000);
          },
          (errors) => {
            this.errors = errors;
          }
        );
      },
    },
  };
  </script>
  